<template>
  <v-navigation-drawer
    :value="isFilterProductSidebarActive"
    temporary
    touchless
    floating
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="val => $emit('update:is-product-filter-sidebar-active', val)"
  >
    <v-sheet :color="isDark ? '#363351' : '#fafafa'" class="pa-3 d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <v-avatar size="48" color="secondary">
          <v-icon size="24" class="white--text">
            {{ icons.mdiFilter }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">Filtreleme</div>
      </div>
      <v-btn icon x-small @click="$emit('update:is-product-filter-sidebar-active', false)">
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <div @keyup.enter="filtering()">
      <v-text-field v-model="filter.id" label="ID" class="rounded-0" filled hide-details />
      <v-text-field v-model="filter.customer" label="Müşteri No" class="rounded-0" filled hide-details />
      <!-- <v-text-field v-model="filter.cc_no" label="Kart No" class="rounded-0" filled hide-details /> -->

      <v-menu
        v-model="startMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter.date"
            label="Başlangıç Tarihi"
            hide-details=""
            readonly
            filled
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="filter.date" no-title @input="startMenu = false"></v-date-picker>
      </v-menu>

      <v-menu v-model="endMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter.endDate"
            label="Bitiş Tarihi"
            hide-details=""
            readonly
            filled
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="filter.endDate" :max="maxDate" no-title @input="endMenu = false"></v-date-picker>
      </v-menu>

      <v-text-field v-model="filter.minPrice" label="Fiyat" class="rounded-0" filled hide-details />
      <!-- <v-text-field v-model="filter.maxPrice" label="Fiyat En Fazla" class="rounded-0" filled hide-details /> -->
    </div>
    <template v-slot:append>
      <v-container>
        <v-row justify="center" align="center">
          <v-col :cols="isFilter ? 8 : 12">
            <v-btn x-large block rounded class="text-capitalize" color="secondary" @click="filtering">
              Filitrele
            </v-btn>
          </v-col>
          <v-col v-if="isFilter" cols="4">
            <v-btn text small rounded plain @click="filterClear"> Temizle </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiFilter, mdiFilterOff } from '@mdi/js'
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {
  model: {
    prop: 'isFilterProductSidebarActive',
    event: 'update:is-product-filter-sidebar-active',
  },
  props: {
    isFilterProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    sendData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      window.addEventListener('keydown', keyDownHandler)
    })
    onUnmounted(() => {
      window.removeEventListener('keydown', keyDownHandler)
    })
    const keyDownHandler = event => {
      if (event.code === 'Escape') {
        emit('update:is-product-filter-sidebar-active', false)
      }
    }

    const maxDate = new Date().toISOString().slice(0, 10)
    const { isDark } = useAppConfig()
    const startDate = ref(null)
    const date = ref(null)
    const endDate = ref(null)
    const startMenu = ref(false)
    const endMenu = ref(false)

    const filter = ref({
      id: '',
      customer: '',
      date: '',
      siparisDurum: '',
      odemeTipi: '',
      startDate: '',
      endDate: '',
      minPrice: '',
      maxPrice: '',
    })
    const defaultFilter = ref({
      id: '',
      customer: '',
      date: '',
      siparisDurum: '',
      odemeTipi: '',
      startDate: '',
      endDate: '',
      minPrice: '',
      maxPrice: '',
    })

    const search = ref()
    const isLoading = ref(false)
    const items = ref([])
    const isFilter = ref(0)
    const filteringStatus = ref(false)

    const filtering = () => {
      filteringStatus.value = true
      emit('filter-data', filter.value)
      emit('update:is-product-filter-sidebar-active', false)
    }
    const filterClear = () => {
      emit('filter-data', {})
      emit('update:is-product-filter-sidebar-active', false)
      filter.value = {
        id: '',
        customer: '',
        date: '',
        minPrice: '',
        maxPrice: '',
        aktif: '',
        baslik: '',
        urun_kodu: '',
        plant: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filitre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }

    watch(
      filter,
      item => {
        let i = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(item)) {
          if (value) {
            i += 1
          }
        }
        isFilter.value = i
      },
      { deep: true },
    )

    watch(
      () => props.isFilterProductSidebarActive,
      val => {
        if (filteringStatus.value) {
        }
        if (!val) {
          filteringStatus.value = false
          if (Object.keys(props.sendData).length > 0) {
            filter.value = { ...props.sendData }
          } else {
            filter.value = { ...defaultFilter.value }
          }
        }
      },
    )

    return {
      items,
      isFilter,
      isLoading,
      isDark,
      filter,
      filtering,
      filterClear,
      search,
      startDate,
      date,
      maxDate,
      endDate,
      startMenu,
      endMenu,
      filteringStatus,
      icons: {
        mdiFilter,
        mdiFilterOff,
        mdiClose,
      },
    }
  },
}
</script>
